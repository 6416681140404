import { useEffect, useState } from 'react';

const ONE_SECOND = 1000;

const getSecondsRemain = (till: number): number => {
    const nowSeconds = Math.floor(Date.now() / ONE_SECOND);
    if (till <= nowSeconds) {
        return 0;
    }
    return till - nowSeconds;
};

const useTimeoutTimer = (till: number): number => {
    const [value, setValue] = useState(() => getSecondsRemain(till));

    useEffect(() => {
        let timeOutHandler: NodeJS.Timeout;
        const updateValue = () => {
            const remain = getSecondsRemain(till);
            setValue(remain);
            if (remain > 0) {
                timeOutHandler = setTimeout(updateValue, ONE_SECOND);
            }
        };
        updateValue();
        return () => clearTimeout(timeOutHandler);
    }, [till]);

    return value;
};

export default useTimeoutTimer;
