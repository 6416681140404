const formatAnalyticErrors = (field, errors, [fieldKey, descriptionKey] = []) => {
    let formattedErrors = [];
    if (typeof errors === 'string') {
        formattedErrors.push({ field, description: errors });
    } else if (Array.isArray(errors) && errors.length) {
        formattedErrors = errors.map((error) => ({ field: error[fieldKey], description: error[descriptionKey] }));
    } else if (errors) {
        formattedErrors.push({ field, description: errors });
    }
    return formattedErrors.length ? JSON.stringify(formattedErrors) : null;
};

export default formatAnalyticErrors;
