import { FieldInputProps } from 'react-final-form';

import { Checkbox as MagritteCheckbox, Text as MagritteText, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Checkbox from 'bloko/blocks/checkbox';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

interface CheckboxProps {
    input: FieldInputProps<boolean | undefined>;
}

const TrlKeys = {
    text: 'advertisementSubscriptionApplicant.checkbox.text',
    linkHh: 'advertisementSubscriptionApplicant.checkbox.text.part.1',
    linkZp: 'advertisementSubscriptionApplicant.checkbox.link_zp',
};

const HH_MARKETING_AGREEMENT_LINK = '/article/marketing_agreement';
const ZP_MARKETING_AGREEMENT_LINK = '/article/32173';

const AdvertisementSubscriptionApplicant: TranslatedComponent<CheckboxProps> = ({ trls, input }) => {
    const isZarplata = useIsZarplataPlatform();
    const isMagritte = useMagritte();

    if (isMagritte) {
        return (
            <label className={styles.checkboxContainer}>
                <MagritteCheckbox {...input} value={String(input.value)} />
                <MagritteText typography="label-2-regular" style="secondary">
                    {formatToReactComponent(trls[TrlKeys.text], {
                        '{0}': (
                            <MagritteLink
                                typography="label-2-regular"
                                inline
                                Element={Link}
                                to={isZarplata ? ZP_MARKETING_AGREEMENT_LINK : HH_MARKETING_AGREEMENT_LINK}
                                target="_blank"
                            >
                                {trls[isZarplata ? TrlKeys.linkZp : TrlKeys.linkHh]}
                            </MagritteLink>
                        ),
                    })}
                </MagritteText>
            </label>
        );
    }

    return (
        <Checkbox {...input} value={String(input.value)}>
            <Text importance={TextImportance.Secondary}>
                {formatToReactComponent(trls[TrlKeys.text], {
                    '{0}': (
                        <BlokoLink
                            disableVisited
                            Element={Link}
                            to={isZarplata ? ZP_MARKETING_AGREEMENT_LINK : HH_MARKETING_AGREEMENT_LINK}
                            target="_blank"
                        >
                            {trls[isZarplata ? TrlKeys.linkZp : TrlKeys.linkHh]}
                        </BlokoLink>
                    ),
                })}
            </Text>
        </Checkbox>
    );
};

export default translation(AdvertisementSubscriptionApplicant);
