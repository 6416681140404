import urlParser from 'bloko/common/urlParser';

import fetcher from 'src/utils/fetcher';

import { useSelector } from 'src/hooks/useSelector';

export const createUser = (values) => {
    return fetcher.postFormData('/account/signup', values);
};

export default (backurl) => {
    const authURLBackurl = useSelector((state) => state.authUrl.backurl);
    const url = urlParser(backurl ?? authURLBackurl);

    return (signup) => {
        if (signup?.data?.verificationResult?.success) {
            window.location.assign(signup?.data?.backurl || `${url.pathname}${url.search}`);
        }
    };
};
