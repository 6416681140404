import { ComponentProps, forwardRef, ForwardRefRenderFunction } from 'react';

import { Input as MagritteInput, UncontrolledInput } from '@hh.ru/magritte-ui';
import BlokoInput, { InputProps as BlokoInputProps, InputType as BlokoInputType } from 'bloko/blocks/inputText';

import useRedesign from 'src/hooks/useMagritte';

type MagritteInputProps = ComponentProps<typeof MagritteInput>;
type MagritteUncontrolledInputProps = ComponentProps<typeof UncontrolledInput>;
type MagrilleInputProps = Partial<MagritteInputProps> | Partial<MagritteUncontrolledInputProps>;
type InputProps = MagrilleInputProps & { uncontrolled?: boolean; blokoComponentProps?: BlokoInputProps };

const getBlokoParams = (params: InputProps, uncontrolled?: boolean): BlokoInputProps => {
    const blokoParams = {
        ...params,
        type: BlokoInputType.Text,
        defaultValue: params.defaultValue ? String(params.defaultValue) : undefined,
        placeholder: typeof params.placeholder === 'string' ? params.placeholder : undefined,
    } as BlokoInputProps;

    if (uncontrolled) {
        blokoParams.defaultValue = params.value;
        delete blokoParams.value;
    }
    return blokoParams;
};

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
    { uncontrolled, size, blokoComponentProps, ...props },
    ref
) => {
    const isRedesign = useRedesign();

    if (isRedesign) {
        return uncontrolled ? (
            <UncontrolledInput {...({ ...props, size, ref } as MagritteUncontrolledInputProps)} />
        ) : (
            <MagritteInput {...({ ...props, size, ref } as MagritteInputProps)} />
        );
    }

    return <BlokoInput {...getBlokoParams(props as InputProps, uncontrolled)} ref={ref} {...blokoComponentProps} />;
};

export default forwardRef(Input);
