import { useState, useEffect, useRef } from 'react';

import { getCurrentSeconds } from 'Utils/Dates';
// Хук для таймера обратного отсчета, возвращает оставшееся количество секунд и коллбэк для его изменения

export default (seconds = 0): [number, (value: number) => void] => {
    const [timeLeft, setTime] = useState(Math.max(seconds, 0));
    const timer = useRef(0);
    useEffect(() => {
        const startTime = getCurrentSeconds();
        const tick = () => {
            const secondsPassed = getCurrentSeconds() - startTime;
            const secondsLeft = timeLeft - secondsPassed;
            if (secondsLeft < 0 || Number.isNaN(secondsLeft)) {
                setTime(0);
                clearTimeout(timer.current);
            } else {
                setTime(secondsLeft);
            }
        };
        timer.current = window.setTimeout(tick, 1000);
        return () => clearTimeout(timer.current);
    }, [timeLeft]);
    return [timeLeft, setTime];
};
