import useTimeoutTimer from 'src/components/OTP/useTimeoutTimer';

const TimeoutCounter: React.FC<
    {
        till: number;
    } & React.PropsWithChildren
> = ({ till }) => {
    const secondsRemain = useTimeoutTimer(till);

    if (secondsRemain < 1) {
        return null;
    }
    return <>{secondsRemain}</>;
};

export default TimeoutCounter;
