import { FC, PropsWithChildren, ComponentProps } from 'react';

import { Title, TitleProps } from '@hh.ru/magritte-ui';
import { H3Section, H2Section, H2 } from 'bloko/blocks/header';

import useMagritte from 'src/hooks/useMagritte';

type BlokoElementType = typeof H3Section | typeof H2Section | typeof H2;
type BlokoProps = ComponentProps<BlokoElementType> & { 'data-qa'?: string };
type HeaderProps = TitleProps &
    PropsWithChildren & {
        BlokoElement: BlokoElementType;
    };

const getBlokoProps = (props: TitleProps): BlokoProps => {
    const blokoProps = { ...props } as BlokoProps;

    if ('dataQaTitle' in blokoProps) {
        delete blokoProps.dataQaTitle;

        blokoProps['data-qa'] = props.dataQaTitle;
    }

    return blokoProps;
};

const Header: FC<HeaderProps> = ({ children, size, Element, BlokoElement, ...props }) => {
    const isMagritte = useMagritte();

    if (isMagritte) {
        return (
            <Title {...props} size={size} Element={Element}>
                {children}
            </Title>
        );
    }

    return <BlokoElement {...getBlokoProps(props as TitleProps)}>{children}</BlokoElement>;
};

export default Header;
