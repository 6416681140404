import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';

import { CounterType } from 'src/models/counters';
import { TopLevelSite } from 'src/models/topLevelSite';

import { useSelector } from 'src/hooks/useSelector';
import { useTopLevelSite } from 'src/hooks/useSites';

const DV360_EVENTS_MAP = {
    VACANCY_SHOWN: {
        [TopLevelSite.AZ]: 'vacan00',
        [TopLevelSite.KZ]: 'vacan0',
        [TopLevelSite.UZ]: 'vacan000',
    },
    RESUME_CREATED: {
        [TopLevelSite.AZ]: 'summa00',
        [TopLevelSite.KZ]: 'summa0',
        [TopLevelSite.UZ]: 'sumuz0',
    },
    EMPLOYER_REGISTERED: {
        [TopLevelSite.AZ]: 'clien00',
        [TopLevelSite.KZ]: 'clien0',
        [TopLevelSite.UZ]: 'clien000',
    },
    APPLICANT_REGISTERED: {
        [TopLevelSite.AZ]: 'appli00',
        [TopLevelSite.KZ]: 'appli0',
        [TopLevelSite.UZ]: 'appli000',
    },
    ANONYMOUS_EMPLOYER_SCREEN_SHOWN: {
        [TopLevelSite.AZ]: null,
        [TopLevelSite.KZ]: 'hhkze0',
        [TopLevelSite.UZ]: 'hhuze0',
    },
    ANONYMOUS_EMPLOYER_PAGE_PUBLISH_VACANCY_BUTTON_CLICK: {
        [TopLevelSite.AZ]: null,
        [TopLevelSite.KZ]: 'hhkze00',
        [TopLevelSite.UZ]: 'hhuze00',
    },
    ANONYMOUS_EMPLOYER_PAGE_FIND_EMPLOYMENT_BUTTON_CLICK: {
        [TopLevelSite.AZ]: null,
        [TopLevelSite.KZ]: 'hhkze000',
        [TopLevelSite.UZ]: 'hhuze000',
    },
    ANONYMOUS_EMPLOYER_PAGE_PRICE_CANDIDATES_BUTTON_CLICK: {
        [TopLevelSite.AZ]: null,
        [TopLevelSite.KZ]: 'hhkze001',
        [TopLevelSite.UZ]: 'hhuze001',
    },
} as const;

type UseDV360Analytics = () => (eventName: keyof typeof DV360_EVENTS_MAP) => void;

export const useDV360Analytics: UseDV360Analytics = () => {
    const topLevelSite = useTopLevelSite();
    const counters = useSelector(({ counters }) => counters);

    return useCallback(
        (event) => {
            if ([TopLevelSite.AZ, TopLevelSite.KZ, TopLevelSite.UZ].includes(topLevelSite)) {
                const goal =
                    DV360_EVENTS_MAP[event][topLevelSite as TopLevelSite.AZ | TopLevelSite.UZ | TopLevelSite.KZ];

                if (goal) {
                    counters.googleUniversalAnalytics?.forEach((item) => {
                        if (item.type === CounterType.DV360) {
                            Analytics.sendDv360Conversion(String(item.id), goal);
                        }
                    });
                }
            }
        },
        [topLevelSite, counters]
    );
};
