import React, { useEffect, useRef } from 'react';

const useAutofocus = (): React.MutableRefObject<HTMLInputElement | null> => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) {
            if (inputRef.current.type !== 'number') {
                inputRef.current.selectionStart = inputRef.current.value.length;
            }
            inputRef.current.focus();
        }
    }, []);
    return inputRef;
};

export const useAutofocusRenderOnce = (): ((value: string) => {
    ref?: React.MutableRefObject<HTMLInputElement | null>;
}) => {
    const refSetted = useRef<boolean>();
    const inputRefToFocus = useAutofocus();
    return (value: string) => {
        if (!value && !inputRefToFocus.current && !refSetted.current) {
            refSetted.current = true;
            return { ref: inputRefToFocus };
        }
        return {};
    };
};

export default useAutofocus;
