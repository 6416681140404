import { useEffect, useRef } from 'react';

import { fingerprintPromise, securePortalFingerprintPromise } from 'HH/FingerprintPromises';

import useIsClient from 'src/hooks/useIsClient';

const useFingerprints = (userType) => {
    const isClient = useIsClient();
    const fingerprints = useRef({});
    useEffect(() => {
        if (!isClient) {
            return;
        }

        fingerprintPromise
            .then((fingerprint) => {
                fingerprints.current.fingerprintIteration2 = fingerprint;
            })
            .catch(console.error);

        if (userType === 'employer') {
            import('fingerprint2-1.5.1')
                .then((FingerprintOld) => {
                    window.requestIdleCallback(() => {
                        new FingerprintOld.default({
                            excludeScreenResolution: true,
                            excludeHasLiedResolution: true,
                        }).get((fingerprintOldHash) => {
                            fingerprints.current.fingerprint = fingerprintOldHash;
                        });
                    });
                })
                .catch(console.error);
        }

        securePortalFingerprintPromise
            .then((fingerprint) => {
                fingerprints.current.fingerprintSp = fingerprint;
            })
            .catch(console.error);
    }, [isClient, userType]);
    return fingerprints.current;
};

export default useFingerprints;
