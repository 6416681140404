import { useEffect, useRef } from 'react';

import PostMessage from 'Modules/PostMessage';
import VK_APP_ORIGIN from 'Modules/constants/auth';

import useIsIframeView from 'src/hooks/useIsIframeView';

/**
 * useVkAppIntegrationReady
 * Хук для интеграции с мини-приложением в VK
 * Подписывает поля формы на postMessage сообщения
 * Формирует и отправляет postMessage сообщение о готовности формы
 *
 * @param {String} formName – имя формы, для которой активируем postMessage сообщения
 * @param {Array} formFields – имена полей формы, для подписки на postMessage сообщения
 * @param {Element} form – react-элемент - форма, поля которой подписываем и изменяем при вызове handler'а
 *
 * Имена полей формы должны соответствовать значениям ключей в объекте data
 * format of data for signup form: { firstName: 'John', lastName: 'Smith', login: 'john.smith@example.com' }
 * format of data for login form: { username: 'john.smith@example.com' }
 */

export const LOGIN_FORM = 'login';
export const SIGNUP_FORM = 'signup';

export default (formName, formFields, form) => {
    const isIframeView = useIsIframeView();
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (!isIframeView) {
            return;
        }

        if (firstRenderRef.current) {
            PostMessage.on({
                origin: VK_APP_ORIGIN,
                handlers: {
                    'pass.data.to.fields': (data) => {
                        formFields
                            .filter((name) => typeof data[name] !== 'undefined')
                            .map((name) => form.change(name, data[name]));
                    },
                },
            });

            PostMessage.trigger({
                target: window.parent,
                origin: VK_APP_ORIGIN,
                message: { name: `${formName}.form.ready` },
            });

            firstRenderRef.current = false;
        }
    }, [form, formFields, formName, isIframeView]);
};
