// tempexp_25028_file
import Cookies from 'bloko/common/Cookies';

const COOKIES_NAME = 'fbclid';

const EVENT_NAME = 'CompleteRegistration';

const sendFbPixelRegistrationConversion = (isB2B = true): void => {
    const fbclidCookies = Cookies.get(COOKIES_NAME);

    if (!fbclidCookies) {
        return;
    }

    const [fbclid, b2cPixelId, b2bPixelId, externalId] = fbclidCookies.split('.');

    const pixelId = isB2B ? b2bPixelId : b2cPixelId;

    const pixelSrc = `https://www.facebook.com/tr?id=${pixelId}&ev=${EVENT_NAME}&cd[external_id]=${externalId}&cd[fbclid]=${fbclid}`;

    const img = document.createElement('img');
    img.src = pixelSrc;
    img.width = 1;
    img.height = 1;
    img.style.display = 'none';
    document.body.appendChild(img);

    Cookies.remove(COOKIES_NAME);
};

export default sendFbPixelRegistrationConversion;
