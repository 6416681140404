import addDays from 'date-fns/addDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfMonth from 'date-fns/endOfMonth';
import isBefore from 'date-fns/isBefore';
import max from 'date-fns/max';
import startOfMonth from 'date-fns/startOfMonth';

import Debug from 'HHC/Debug';
import formatDate from 'Modules/formatDate';

/**
 * Получить отформатированные секунды для таймера, с количеством часов или без
 *
 * @param {Number} seconds
 *
 * @returns {String}
 */
export const formatSeconds = (seconds) => {
    const date = new Date(0, 0, 0, 0, 0, seconds);
    const format = date.getHours() > 0 ? 'HH:mm:ss' : 'mm:ss';
    return formatDate(date, format);
};

const getSeconds = (date) => Math.floor(date / 1000);
export const getCurrentSeconds = () => getSeconds(Date.now());

export const IntervalMessages = (humanDatesRules) => {
    const humanDatesList = Object.keys(humanDatesRules).sort((a, b) => Math.sign(b - a));

    const get = (deltaTime) => {
        const deltaSeconds = getSeconds(deltaTime);
        const messageIndex = humanDatesList.findIndex((i) => i < deltaSeconds);

        try {
            return humanDatesRules[humanDatesList[messageIndex]].translation;
        } catch (error) {
            Debug.log('error out', 'messageIndex is wrong', {
                error,
                humanDatesList,
                messageIndex,
                humanDatesRules,
                timeLeft: deltaTime,
            });
        }

        return null;
    };

    return { get };
};

export const calculateDisabledDates = (selectedDate, lastAvailableDate = new Date()) => {
    if (isBefore(selectedDate, startOfMonth(lastAvailableDate))) {
        return [];
    }

    const start = max([startOfMonth(selectedDate), addDays(lastAvailableDate, 1)]);
    const end = endOfMonth(selectedDate);

    if (!isBefore(start, end)) {
        return [];
    }

    return eachDayOfInterval({ start, end }).map((disabledDate) => formatDate(disabledDate, 'y-MM-dd'));
};
