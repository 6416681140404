import { useRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FormError, FormSeparator } from 'bloko/blocks/form';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';

import Debug from 'HHC/Debug';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

export const ERROR_RECAPTCHA_VERIFICATION = 'failed';

const CHECK_RECAPTCHA_TIMEOUT_MS = 300;

const renderRecaptcha = (element, wrapper, siteKey, widgetId) => {
    requestAnimationFrame(() => {
        try {
            if (widgetId.current !== null) {
                window.grecaptcha.reset(widgetId.current);
                return;
            }
            widgetId.current = window.grecaptcha.render(element, {
                sitekey: siteKey,
            });
            wrapper.classList.remove('g-hidden');
        } catch (error) {
            Debug.log('error out', error, { isRecaptcha: true });
        }
    });
};

let scriptAppended = false;

const loadScript = (lang) => {
    if (scriptAppended) {
        return;
    }

    const script = document.createElement('script');
    script.src = `https://recaptcha.net/recaptcha/api.js?render=explicit&hl=${lang}&_=${Date.now()}`;
    document.body.appendChild(script);
    scriptAppended = true;
};

const Recaptcha = ({ isBot, siteKey, error, className, trls, WrapperField }) => {
    const wrapper = useRef(null);
    const element = useRef(null);
    const widgetId = useRef(null);
    const lang = useContext(TranslationLangContext);

    useEffect(() => {
        if (!isBot) {
            return undefined;
        }

        loadScript(lang);

        let timerId;

        const checkToRender = () => {
            clearTimeout(timerId);
            if (window.grecaptcha?.render) {
                renderRecaptcha(element.current, wrapper.current, siteKey, widgetId);
            } else {
                timerId = setTimeout(checkToRender, CHECK_RECAPTCHA_TIMEOUT_MS);
            }
        };

        checkToRender();

        return () => {
            clearTimeout(timerId);
        };
    }, [isBot, lang, error, siteKey]);

    const content = <div ref={element} className={className} />;

    return (
        <>
            <div ref={wrapper} className={!isBot ? 'g-hidden' : ''}>
                <input type="hidden" name="isBot" value={isBot} />
                {WrapperField ? (
                    <WrapperField>{content}</WrapperField>
                ) : (
                    <>
                        <VSpacing base={2} />
                        {content}
                    </>
                )}
            </div>
            {error && <FormSeparator />}
            <FormError show={error}>{trls[Recaptcha.trls.error]}</FormError>
        </>
    );
};

Recaptcha.propTypes = {
    isBot: PropTypes.bool,
    siteKey: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    trls: PropTypes.object,
    WrapperField: PropTypes.elementType,
};

Recaptcha.trls = {
    error: 'error.signup.captcha.invalid',
};

const RecaptchaWithTRL = translation(Recaptcha);

const RecaptchaWrapper = (props) => {
    const recaptcha = useSelector(({ recaptcha }) => recaptcha);
    const isFirstRender = useRef(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(recaptcha?.isBot && isFirstRender.current);
        isFirstRender.current = !!recaptcha?.isBot;
    }, [recaptcha]);

    return <RecaptchaWithTRL error={error} {...recaptcha} {...props} />;
};

export default RecaptchaWrapper;
