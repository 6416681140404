import { ComponentProps, ReactNode, forwardRef, ForwardRefRenderFunction } from 'react';

import { Button as MagritteButton, ButtonProps as MagritteButtonProps } from '@hh.ru/magritte-ui';
import BlokoButton, {
    ButtonKind as BlokoButtonKind,
    ButtonAppearance as BlokoButtonAppearance,
} from 'bloko/blocks/button';
import BlokoLoading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

import useRedesign from 'src/hooks/useMagritte';

type BlokoButtonProps = ComponentProps<typeof BlokoButton> & {
    'data-qa'?: string;
    key?: string;
    to?: string;
    href?: string;
};

type MagritteButtonPropsWithDefault = MagritteButtonProps & {
    to?: string;
    href?: string;
    ariaLabel?: never;
    children: ReactNode;
    hideLabel?: never;
    icon?: ReactNode;
    subcaption?: never;
    tabIndex?: number;
    disableVisited?: boolean;
    target?: string;
};

const getBlokoParams = (params: MagritteButtonPropsWithDefault): BlokoButtonProps => {
    const blokoParams: Partial<BlokoButtonProps> = {
        Element: params.Element,
        stretched: params.stretched,
        onClick: params.onClick,
        type: params.type as BlokoButtonProps['type'],
        children: params.children,
        disabled: params.disabled,
        'data-qa': params['data-qa'],
        to: params.to,
        href: params.href,
        icon: params.icon,
    };
    if (params.loading) {
        blokoParams.loading = <BlokoLoading initial={LoadingColor.White} scale={LoadingScale.Small} />;
    }
    if (params.style === 'accent') {
        blokoParams.kind = BlokoButtonKind.Primary;
    }
    if (params.mode === 'tertiary') {
        blokoParams.appearance = BlokoButtonAppearance.Flat;
    }
    return blokoParams;
};

const Button: ForwardRefRenderFunction<
    HTMLElement,
    MagritteButtonPropsWithDefault & { blokoComponentProps?: BlokoButtonProps }
> = ({ blokoComponentProps, ...props }, ref) => {
    const isRedesign = useRedesign();

    if (!isRedesign) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <BlokoButton {...getBlokoParams(props)} {...blokoComponentProps} ref={ref} />;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <MagritteButton {...props} ref={ref} />;
};

export default forwardRef(Button);
