import { Fragment, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import Analytics from '@hh.ru/analytics-js';
import { useCaptcha } from '@hh.ru/hhcaptcha';
import { Link as MagritteLink, Text } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { FormSeparator } from 'bloko/blocks/form';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { formatSeconds } from 'Utils/Dates';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import Hhcaptcha from 'src/components/Hhcaptcha';
import defaultError from 'src/components/Notifications/DefaultError';
import { useNotification } from 'src/components/Notifications/Provider';
import Recaptcha from 'src/components/Recaptcha';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import useTimer from 'src/hooks/useTimer';
import { OtpRequest } from 'src/models/applicant/auth';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    send: 'otp.resend.button',
    sendSms: 'otp.resend.sms.button',
    wait: 'otp.resend.timer',
    waitSms: 'otp.resend.sms.timer',
};

// из всех описанных урлов FetcherPostApi оставляем только те которые по запросу/ответу удовлетворяют интерфейсу OtpRequest
export type AvailableOtpUrls = keyof {
    [K in keyof FetcherPostApi as FetcherPostApi[K] extends OtpRequest ? K : never]: FetcherPostApi[K];
};

const otpAction = makeSetStoreField('otp');

const CodeSender: TranslatedComponent<{
    login?: string;
    isSignupPage?: boolean;
    url: AvailableOtpUrls;
    otpType: string;
    operationType?: string;
    recaptchaClassName?: string;
    disableCaptcha?: boolean;
}> = ({ login, trls, isSignupPage, url, otpType, operationType, recaptchaClassName = '', disableCaptcha }) => {
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
    const { secondsUntilNextSend } = useSelector(({ otp }) => otp);
    const recaptcha = useSelector(({ recaptcha }) => recaptcha);
    const hhcaptcha = useSelector(({ hhcaptcha }) => hhcaptcha);
    const otp = useSelector(({ otp }) => otp);
    const [sendTimer, setSendTimer] = useTimer(secondsUntilNextSend);
    const elementRef = useRef<HTMLDivElement>(null);
    const captcha = useCaptcha();
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const isMagritte = useMagritte();
    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const sendCode = useCallback(async () => {
        const captchaInput = elementRef.current?.querySelector('.g-recaptcha-response') as HTMLInputElement | undefined;
        const params: OtpRequest['body'] = {
            login,
            otpType,
            operationType,
        };
        let response: AxiosResponse<OtpRequest['response'], unknown> | null = null;

        captcha.addCaptchaParams(params);

        if (captchaInput?.value && recaptchaValue !== captchaInput.value) {
            params['g-recaptcha-response'] = captchaInput.value;
            captchaInput?.value && setRecaptchaValue(captchaInput.value);
        }

        Analytics.sendHHEventButtonClick(
            'resend_code',
            {
                isSignupPage,
                vacancyId,
                otpType,
                operationType,
            },
            { login }
        );

        try {
            response = await fetcher.postFormData(url, params);
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!err?.response?.data?.recaptcha || !err?.response?.data?.hhcaptcha) {
                defaultRequestErrorHandler(err, addNotification);
                return;
            }
        }

        if (response) {
            response.data?.otp && dispatch(otpAction({ ...otp, authType: response.data.otp?.authType }));
            response.data && captcha.updateCaptcha(response.data);
            setSendTimer(response.data?.otp?.secondsUntilNextSend || 0);
        } else {
            addNotification(defaultError);
        }
    }, [
        login,
        otpType,
        operationType,
        captcha,
        recaptchaValue,
        isSignupPage,
        vacancyId,
        url,
        addNotification,
        dispatch,
        otp,
        setSendTimer,
    ]);

    const linkProps = {
        onClick: sendCode,
        'data-qa': 'oauth-merge-by-code__code-resend',
        children: otp.authType === 'WHATS_APP' ? trls[TrlKeys.sendSms] : trls[TrlKeys.send],
    };

    const link = isMagritte ? (
        <MagritteLink {...linkProps} />
    ) : (
        <BlokoLink appearance={LinkAppearance.Pseudo} {...linkProps} />
    );

    const renderTime = () => {
        const timerMessage = otp.authType === 'WHATS_APP' ? trls[TrlKeys.waitSms] : trls[TrlKeys.wait];
        const timer = <p suppressHydrationWarning>{format(timerMessage, { '{0}': formatSeconds(sendTimer) })}</p>;
        if (isMagritte) {
            return <Text style="tertiary">{timer}</Text>;
        }
        return timer;
    };

    return (
        <div ref={elementRef}>
            {!disableCaptcha && (
                <>
                    <Recaptcha WrapperField={Fragment} className={recaptchaClassName} />
                    <Hhcaptcha size="large" />
                    {(recaptcha?.isBot || hhcaptcha?.isBot) && <FormSeparator />}
                </>
            )}
            {sendTimer > 0 ? renderTime() : link}
        </div>
    );
};

export default translation(CodeSender);
