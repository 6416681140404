import Column from 'bloko/blocks/column';
import { FormItem } from 'bloko/blocks/form';
import { H2Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import MultiAccountGroupingForm, {
    MultiAccountGroupingFormBaseProps,
} from 'src/components/ApplicantSignInSignUpFlow/MultiAccountGroupingStep/MultiAccountGroupingForm';

export interface MultiAccountGroupingProps extends MultiAccountGroupingFormBaseProps {
    errorPageLogin?: boolean;
}

const TrlKeys = {
    passwordSent: 'registration.registered.passwordSent',
    letterComeSoon: 'registration.registered.letterComeSoon',
    letterMultiaccountNote: 'registration.registered.letterMultiaccountNote',
    letterHighlightedPart: 'registration.registered.letterHighlightedPart',
};

const MultiAccountGrouping: TranslatedComponent<MultiAccountGroupingProps> = ({
    trls,
    errorPageLogin = false,
    ...formProps
}) => {
    const { login } = useSelector(({ otp }) => otp);

    return (
        <MultiAccountGroupingForm
            render={({ renderInput, renderError, renderSubmit }) => {
                return (
                    <Column l="6" m="6" s="6" xs="4" container={errorPageLogin}>
                        <H2Section>
                            {format(trls[TrlKeys.passwordSent], {
                                '{0}': login as string,
                            })}
                        </H2Section>
                        <VSpacing base={3} />
                        <p>
                            {formatToReactComponent(trls[TrlKeys.letterMultiaccountNote], {
                                '{0}': (
                                    <Text Element="span" strong>
                                        {trls[TrlKeys.letterHighlightedPart]}
                                    </Text>
                                ),
                            })}
                        </p>
                        <p>{trls[TrlKeys.letterComeSoon]}</p>
                        <VSpacing base={6} />
                        <FormItem>{renderInput()}</FormItem>
                        {renderError()}
                        <VSpacing base={8} />
                        {renderSubmit({ isStretched: !errorPageLogin })}
                    </Column>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(MultiAccountGrouping);
